import Vue from 'vue'
import Vuex from 'vuex'
import Clientes from './modules/clientes/clientes'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

require('dotenv').config()

export default new Vuex.Store({
	modules: {
		clientes: Clientes
	},
	state: {
		status: localStorage.getItem('status') || '',
		token: localStorage.getItem('token') || '',
		user: {}
	},
	plugins: [createPersistedState({
		storage: window.sessionStorage,
		paths: ['token', 'status', 'user']
	})],

	actions: {
		login ({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios({ url: process.env.VUE_APP_API_ENDPOINT + 'APIUserLogin/', data: user, method: 'POST' })
					.then(resp => {
						// console.log(resp)
						const token = resp.data.token
						localStorage.setItem('token', 'Token ' + token)
						localStorage.setItem('user', JSON.stringify(resp.data.user))

						axios.defaults.headers.common.Authorization = localStorage.getItem('token')
						commit('auth_success', resp)
						resolve(resp)
					})
					.catch(err => {
						commit('auth_error')
						console.log(err)
						localStorage.removeItem('token')
						reject(err)
					})
			})
		},
		register ({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios({ url: process.env.VUE_APP_API_ENDPOINT + 'register/', data: user, method: 'POST' })
					.then(resp => {
						const token = resp.data.token
						axios.defaults.headers.common.Authorization = 'Token ' + token
						localStorage.setItem('token', 'Token ' + token)
						// Add the following line:
						axios.defaults.headers.common.Authorization = localStorage.getItem('token')
						commit('auth_success', resp)
						resolve(resp)
					})
					.catch(err => {
						commit('auth_error', err)
						console.log('error auth')
						localStorage.removeItem('token')
						reject(err)
					})
			})
		},
		logout ({ commit }) {
			return new Promise((resolve, reject) => {
				commit('logout')
				// localStorage.removeItem('status')
				// localStorage.removeItem('token')
				delete axios.defaults.headers.common.Authorization
				resolve()
			})
		}
	},
	mutations: {
		auth_request (state) {
			state.status = 'loading'
		},
		auth_success (state, resp) {
			// console.log(resp)
			state.status = 'success'
			state.token = 'Token ' + resp.data.token
			state.user = resp.data.user
		},
		auth_error (state) {
			state.status = 'error'
		},
		logout (state) {
			state.status = ''
			state.token = ''
		}
	},
	getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
		type_user: state => state.credenciales,
		user: state => state.user
	}

})
