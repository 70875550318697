import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	/* eslint-disable */
  routes: [
    {
      
      path: '/', name: 'home', component: () => import('../components/outside/Home.vue')
      
    },

    {
      path: '/auth',
      name: 'auth',
      component: () => import('../views/Auth.vue'),
      beforeEnter (to, from, next) { if (store.getters.authStatus !== 'success') { next() } else { next('/') } }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('../views/Panel.vue'),
      beforeEnter (to, from, next) { if (store.getters.authStatus === 'success') { next() } else { next('/auth') } },
      children: [
        { path: '/', component: () => import('../components/inside/principal/index.vue') },
        { path: '/clientes', component: () => import('../components/inside/clientes/clientes.vue') }
        // { path: '/device/:name', component: () => import('./components/inside/device/device.vue'), props: true ,name:'device'},
      ]
    },
    { path: '*', redirect: '/' }
  ]
  /* eslint-enable */
})
