import axios from 'axios'

const defaultState = {
	clientesData: []

}

const actions = {
	getClientes ({ commit, rootState }, table) {
		return new Promise((resolve, reject) => {
			axios({ url: process.env.VUE_APP_API_ENDPOINT + 'cliente/clientesAll/', data: table, method: 'POST' })
				.then(resp => {
					commit('CLIENTES_DATA_UPDATE', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	setCliente ({ commit, rootState }, data) {
		return new Promise((resolve, reject) => {
			axios({ url: process.env.VUE_APP_API_ENDPOINT + 'cliente/clientes/', data: data, method: 'POST' })
				.then(resp => {
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
		})
	}
}

const mutations = {
	CLIENTES_DATA_UPDATE: (state, clientesData) => {
		state.clientesData = clientesData
	}
}

const getters = {
	clientesData: state => state.clientesData
}

export default {
	state: defaultState,
	getters,
	actions,
	mutations
}
