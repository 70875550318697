import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import mixin from './global/funtions'

Vue.config.productionTip = false

Vue.prototype.$http = Axios
/* eslint-disable */
require('dotenv').config()

if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
} else {
  Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
}
/* eslint-enable */

Axios.interceptors.request.use(function (config) {
	return config
}, function (error) {
	return Promise.reject(error)
})

Vue.mixin(mixin)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
